/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    code: "code",
    h3: "h3",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu is built using security best practices such as the principle of least privilege. So you\ncan assume that your account and data in Signatu is safe as long as you do your part."), "\n", React.createElement(_components.h2, {
    id: "password",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#password",
    "aria-label": "password permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Password"), "\n", React.createElement(_components.p, null, "Make sure you have ", React.createElement(_components.a, {
    href: "https://www.howtogeek.com/195430/how-to-create-a-strong-password-and-remember-it/"
  }, "secure password"), ",\nand try not to re-use passwords from other sites. We strongly suggest using a password manager such as\n", React.createElement(_components.a, {
    href: "https://dashlane.com"
  }, "Dashlane"), " and ", React.createElement(_components.a, {
    href: "https://1password.com"
  }, "1password"), "."), "\n", React.createElement(_components.h2, {
    id: "oauth-tokens",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oauth-tokens",
    "aria-label": "oauth tokens permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OAuth tokens"), "\n", React.createElement(_components.p, null, "You can generate OAuth tokens either in ", React.createElement(_components.a, {
    href: "/app/account#tokens"
  }, "your Signatu account"), " or using\nclient application credentials. Make sure you restrict these tokens to the specific scope they are for,\ne.g., ", React.createElement(_components.code, null, "consent"), " or ", React.createElement(_components.code, null, "dataprocessing"), ". Note that while these tokens will give the bearer limited access right\nfor the given scope(s), they do access your account, and should be kept secret."), "\n", React.createElement(_components.h2, {
    id: "websites",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#websites",
    "aria-label": "websites permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Websites"), "\n", React.createElement(_components.p, null, "If you want your website to use the Signatu Consent API directly you need to provide the web browser with\na valid OAuth token. This token will be readable by your users, and hence cannot be considered secret anymore.\nAnyone with this token can POST new consent events and query the API."), "\n", React.createElement(_components.h3, {
    id: "content-security-policy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#content-security-policy",
    "aria-label": "content security policy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Content Security Policy"), "\n", React.createElement(_components.p, null, "If you are using ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy"
  }, "Content Security Policy (CSP)"), " on your site\nyou need to include ", React.createElement(_components.code, null, "signatu.com"), " in ", React.createElement(_components.code, null, "connect-src"), ". If you embed a Signatu Policy button, you also need to include ", React.createElement(_components.code, null, "cdn.signatu.com"), " in your ", React.createElement(_components.code, null, "frame-src"), "."), "\n", React.createElement(_components.h2, {
    id: "proxying-requests",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#proxying-requests",
    "aria-label": "proxying requests permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Proxying requests"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/93740fdee8178ad5873c4fcc8d4dce5c/api-proxy.svg",
    alt: "API Proxy example"
  })), "\n", React.createElement(_components.p, null, "You can receive consent events to your service. Your service will have OAuth client credentials, and\ncan forward events to the Signatu API. You need a way to authenticate your applications to the Consent Proxy, otherwise\nthere is no additional security for this model."), "\n", React.createElement(_components.p, null, "The Signatu SDK contains an ", React.createElement(_components.a, {
    href: "sdk/nodejs"
  }, "example Express proxy")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
